import { createTheme } from '@mui/material/styles';

let portalTheme = createTheme({
    palette: {
        primary: {
            main: '#0097A9',
            dark: '#008797',
            light: '#F1F1F1',
        },
        secondary: {
            contrastText: '#fff',
            main: '#243A62',
            light: '#D1D1D1',
            dark: '#9E9E9E',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
});

portalTheme = createTheme(portalTheme, {
    typography: {
        h3: {
            fontSize: '36px',
            lineHeight: '44px',
            fontWeight: 700,
            color: '#333333',
            [portalTheme.breakpoints.down('lg')]: {
                fontSize: '28px',
                lineHeight: '34px',
            },
            [portalTheme.breakpoints.down('md')]: {
                fontSize: '26px',
                lineHeight: '30px',
            },
            [portalTheme.breakpoints.down('sm')]: {
                fontSize: '22px',
                lineHeight: '26px',
            },
        },
        body1: {
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: 400,
            color: '#666666',
        },
        body2: {
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 400,
            color: '#333333',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                    transition: 'all 300ms ease',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '18px',
                    lineHeight: '32px',
                    fontWeight: '500',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    '&:hover': { boxShadow: 'none' },
                    '&:focus': { boxShadow: 'none' },
                },
                containedPrimary: {
                    color: '#ffffff',
                    textTransform: 'capitalize',
                    padding: '10px 30px',
                    [portalTheme.breakpoints.down('md')]: {
                        padding: '8px 12px',
                    },
                    '&:hover': { backgroundColor: [portalTheme.palette.primary.main] },
                },
                containedSizeSmall: {
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: '600',
                    color: '#ffffff',
                    textTransform: 'capitalize',
                    padding: '10px 20px',
                    [portalTheme.breakpoints.down('md')]: {
                        padding: '8px',
                    },
                },
                outlinedPrimary: {
                    textTransform: 'capitalize',
                    padding: '5px 16px',
                    [portalTheme.breakpoints.down('md')]: {
                        padding: '6px',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '12px 0px',
                    background: [portalTheme.palette.secondary.contrastText],
                    borderRadius: '8px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#616161',
                    padding: '8px 20px',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.primary-accordion': {
                        borderRadius: '10px',
                        border: 0,
                        boxShadow: 'none',
                        overflow: 'hidden',
                        marginBottom: '16px',
                        transition: 'all 0.3s ease',

                        '&:before': {
                            display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                            padding: '18px',
                            backgroundColor: '#EFFAFC',
                            [portalTheme.breakpoints.down('md')]: {
                                padding: '8px 12px',
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                            '& .MuiTypography-root': {
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '35px',
                                color: '#333333',
                                // textTransform: 'capitalize',
                                [portalTheme.breakpoints.down('md')]: {
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                },
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            backgroundColor: '#EFFAFC',
                            padding: '16px 16px 16px 37px',
                            [portalTheme.breakpoints.down('md')]: {
                                padding: '16px',
                            },
                        },

                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '& svg': { fill: '#666666' },
                        },
                        '&.Mui-expanded': {
                            '& .MuiAccordionSummary-root': {
                                padding: '18px',
                                backgroundColor: [portalTheme.palette.primary.main],
                                minHeight: 'auto',
                                [portalTheme.breakpoints.down('md')]: {
                                    padding: '8px 12px',
                                },
                                '& .MuiTypography-root': {
                                    color: '#FFFFFF',
                                },
                            },
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                '& svg': { fill: '#FFFFFF' },
                            },
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    input: {
                        padding: '12px 20px',
                        height: 24,
                        color: '#666666',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D6D6D6',
                        borderRadius: '6px',
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {},
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#333333',
                    fontSize: '14px',
                    lineHeight: '20px',
                    paddingBottom: '4px',
                },
            },
        },

        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    border: 0,
                    borderRadius: 5,
                    '& .MuiTableHead-root': {
                        '& .MuiTableCell-head': {
                            padding: '15px 16px',
                            fontSize: '18px',
                            lineHeight: '24px',
                            fontWeight: 600,
                            color: '#003C44',
                            textTransform: 'capitalize',
                            backgroundColor: '#EFFAFC',
                            textAlign: 'center',
                            '& span': {
                                display: 'block',
                                fontSize: '13px',
                                fontWeight: 400,
                            },
                            '&.portal-head': {
                                backgroundColor: [portalTheme.palette.primary.main],
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #BDDFE5',
                    borderRight: '1px solid #BDDFE5',
                    height: '50px',
                },
                body: {
                    padding: '10px 20px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    color: '#245860',
                    textAlign: 'center',
                    img: {
                        display: 'block',
                        margin: '0 auto',
                    },
                },
            },
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    th: {
                        backgroundColor: '#EFFAFC',
                        fontSize: '15px',
                        lineHeight: '20px',
                        fontWeight: 600,
                        color: '#003C44',
                        textAlign: 'left',
                        borderTop: '1px solid #BDDFE5',
                    },
                    '& .title-cell': {
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 500,
                        color: '#003C44',
                        textAlign: 'left',
                        position: 'sticky',
                        left: 0,
                        background: '#FFFFFF',
                        borderLeft: '1px solid #BDDFE5',
                    },
                    '& .portal-cell': {
                        backgroundColor: '#EFFAFC',
                    },
                    '& .sticky-cell': {
                        position: 'sticky',
                        left: 0,
                        borderLeft: '1px solid #BDDFE5',
                    },
                    '&:last-child': {
                        '& .MuiTableCell-body': {},
                    },
                },
            },
        },
    },
});

export default portalTheme;
