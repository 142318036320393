import { Box, Divider, Link } from '@mui/material';
import images from 'assets/images';
import React from 'react';
import ScrollSpy from 'react-scrollspy-navigation';

interface IHeaderDrawerProps {
    handleDrawerToggle: () => void;
}

const HeaderDrawer: React.FC<IHeaderDrawerProps> = ({ handleDrawerToggle }) => {
    return (
        <Box onClick={handleDrawerToggle}>
            <Link href="" title="Health Reach" className="drawer-logo-block">
                <img src={images.logo} alt="Logo" />
            </Link>
            <Divider />
            <div className="drawer-block">
                <ScrollSpy offsetTop={80}>
                    <a href="#home" ref={React.createRef()} title="Home" className="nav-link">
                        Home
                    </a>

                    <a href="#about-us" ref={React.createRef()} title="About Us" className="nav-link">
                        About Us
                    </a>
                    <a href="#practice-with-us" ref={React.createRef()} title="Practice WIth Us" className="nav-link">
                        Practice WIth Us
                    </a>

                    <a href="#access-service" ref={React.createRef()} title="Access Clinicians" className="nav-link">
                        Access Clinicians
                    </a>

                    <a href="#news" ref={React.createRef()} title="Health News" className="nav-link">
                        Health News
                    </a>

                    <a href="#faq" ref={React.createRef()} title="FAQ" className="nav-link">
                        FAQ
                    </a>
                </ScrollSpy>
            </div>
        </Box>
    );
};

export default HeaderDrawer;
