/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISystemSettingModel } from 'models/setting';
import { AuthActionType } from 'store/actionTypes/setting-action-type';
import { IAction } from 'store/state/app-state';

export const SettingReducer = (state: ISystemSettingModel = null, action: IAction<any>): ISystemSettingModel => {
    switch (action.type) {
        case AuthActionType.UPDATE_SETTING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
