import { AppBar, Box, Button, Drawer, IconButton, Link, Menu, MenuItem, Toolbar } from '@mui/material';
import { Container } from '@mui/system';
import images from 'assets/images';
import { HeaderDrawer } from 'layout';
import React from 'react';
import { useSelector } from 'react-redux';
import ScrollSpy from 'react-scrollspy-navigation';
import { Routing } from 'shared/constants/routing';
import { IApplicationState } from 'store/state/app-state';

const drawerWidth = 260;

const Header: React.FC = () => {
    const setting = useSelector((state: IApplicationState) => state?.Setting);
    const spanRef = React.useRef(null);

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(spanRef.current);

    const open = Boolean(anchorEl);

    const handleDrawerToggle = () => setMobileOpen((prevState) => !prevState);

    const handleActionOpen = () => setAnchorEl(spanRef.current);
    // const handleActionOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event?.currentTarget);
    const handleActionClose = () => setAnchorEl(null);

    const handleClinicianLoginClick = () => {
        handleActionClose();
        window.open(setting?.cliniciaN_PORTAL_APPLICATION_URL, '_blank');
    };

    const handlePatientLoginClick = () => {
        handleActionClose();
        window.open(setting?.patienT_PORTAL_APPLICATION_URL, '_blank');
    };

    const renderMenu = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleActionClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="user-dropdown"
            >
                <MenuItem onClick={handleClinicianLoginClick}>
                    <img src={images.dropDownUserOne} alt="User Profile" className="user-profile" />
                    Clinician Login
                </MenuItem>
                <MenuItem onClick={handlePatientLoginClick}>
                    <img src={images.dropDownUserTwo} alt="User Profile" className="user-profile" />
                    Patient Login
                </MenuItem>
            </Menu>
        );
    };

    return (
        <>
            <AppBar component="nav" className="header">
                <div className="header-inner-block">
                    <Container className="contanier">
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { lg: 'none' } }}
                                className="nav-toggler"
                            >
                                <img src={images.menuIcon} alt="Icon" />
                            </IconButton>

                            <Link href={Routing.Landing} title="Health Reach" className="navbar-brand">
                                <img src={images.logo} alt="Logo" />
                            </Link>
                            <Box sx={{ display: { xs: 'none', lg: 'block' } }} className="navbar-nav">
                                <ScrollSpy offsetTop={80} duration={1500}>
                                    <a href="#home" ref={React.createRef()} title="Home" className="nav-link">
                                        Home
                                    </a>

                                    <a href="#about-us" ref={React.createRef()} title="About Us" className="nav-link">
                                        About Us
                                    </a>
                                    <a
                                        href="#practice-with-us"
                                        ref={React.createRef()}
                                        title="Practice With Us"
                                        className="nav-link"
                                    >
                                        Practice With Us
                                    </a>

                                    <a
                                        href="#access-service"
                                        ref={React.createRef()}
                                        title="Access Clinicians"
                                        className="nav-link"
                                    >
                                        Access Clinicians
                                    </a>

                                    <a href="#news" ref={React.createRef()} title="Health News" className="nav-link">
                                        Health News
                                    </a>

                                    <a href="#faq" ref={React.createRef()} title="FAQ" className="nav-link">
                                        FAQ
                                    </a>
                                </ScrollSpy>
                            </Box>
                            <Button
                                ref={spanRef}
                                variant="contained"
                                size="small"
                                title="Login"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleActionOpen}
                                className="login-btn"
                            >
                                Login
                            </Button>
                        </Toolbar>
                    </Container>
                </div>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <HeaderDrawer handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
            </Box>
            {renderMenu()}
        </>
    );
};

export default Header;
