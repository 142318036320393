/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Typography, Box } from '@mui/material';
import MainLayout from 'layout/MainLayout';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Grid } from '@mui/material';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            errorInfo: null,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): any {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
    }

    render(): any {
        const { hasError, error } = this.state;
        if (hasError) {
            return (
                <MainLayout>
                    <div className="page-with-box">
                        <Helmet>
                            <title>HR - Error</title>
                        </Helmet>
                        <Container maxWidth="xl">
                            <div className="page-with-box-container">
                                <Box className="page-with-box-content d-block p-1">
                                    <Typography variant="h1" className="mb-5">
                                        Something went wrong
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {error?.cause && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" className="key-data minW-120p mr-1">
                                                    cause
                                                </Typography>
                                                <Typography variant="body2" className="value-data">
                                                    {(error as any)?.cause}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {error?.name && (
                                            <Grid item xs={12}>
                                                <Typography variant="h5" className="key-data mr-1">
                                                    Name
                                                </Typography>
                                                <Typography variant="h5" className="value-data">
                                                    {error?.name}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {error?.message && (
                                            <Grid item xs={12} className="d-sm-flex">
                                                <Typography variant="h5" className="key-data mr-1">
                                                    Message
                                                </Typography>
                                                <Typography variant="h5" className="value-data">
                                                    {error?.message}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </div>
                        </Container>
                    </div>
                </MainLayout>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
