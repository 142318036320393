export enum HttpStatusCode {
    BadRequest = 400,
    ConflictError = 409,
    InternalServerError = 500,
    Success = 200,
    Error = 201,
    UserNotFound = 204,
    Unauthorized = 401,
    PasswordExpired = 206,
    NeedOtpConfirmation = 205,
    PatientPendingConsentStatus = 213,
    OTPExpired = 203,
    AllNotificationRead = 248,
    VideoLinkExpired = 250,
    ClinicianNotAvailable = 251,
    ReferralStatusNotDraft = 253,
    QuestionNotAttempt = 258,
    RefreshPage = 506,
}
