import React, { lazy } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Routing } from './constants/routing';

/* Designer Pages */

/* Developer Pages */
const MainLayout = lazy(() => import('layout/MainLayout'));
const DesignerLanding = lazy(() => import('pages/UI/Landing'));
const Landing = lazy(() => import('pages/Landing'));

interface IRoute {
    path: string;
    component: React.LazyExoticComponent<React.FC<{ any }>> | React.ReactNode;
    hideHeader?: boolean;
    exact?: boolean;
    action?: string;
    page?: string;
}

const routes: IRoute[] = [
    {
        path: Routing.DesignerLanding,
        component: DesignerLanding,
    },
    {
        path: Routing.Landing,
        component: Landing,
    },
];

const AppRouting: React.FC = () => {
    return (
        <BrowserRouter>
            <MainLayout>
                <Switch>
                    {routes.map(({ path, component, exact = true }, key) => (
                        <Route exact={exact} path={path} component={component} key={key} />
                    ))}
                    <Redirect to={{ pathname: Routing.Landing }} />
                </Switch>
            </MainLayout>
        </BrowserRouter>
    );
};

export default AppRouting;
