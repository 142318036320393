import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import { Loader, Spinner } from 'layout';
import AppRouting from './shared/AppRouting';
import portalTheme from 'themes/App.theme';
import ErrorBoundary from './shared/ErrorBoundary';
//import "./lang/i18n";

const App: React.FC = () => (
    <Suspense fallback={<Spinner />}>
        <ToastContainer limit={3} className="toaster" hideProgressBar={true} />
        <Loader />
        <ThemeProvider theme={portalTheme}>
            <ErrorBoundary>
                <AppRouting />
            </ErrorBoundary>
        </ThemeProvider>
    </Suspense>
);

export default App;
