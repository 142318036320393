/* eslint-disable @typescript-eslint/no-var-requires */
export default {
    LogoPng: require('./logo.png'),
    logo: require('./logo.svg').default,
    arrowForward: require('./arrow-forward.svg').default,
    btnArrowIcon: require('./btn-arrow-ic.svg').default,
    heroFrame: require('./hero-frame.png'),
    heroOnlineIcon: require('./hero-online-ic.svg').default,
    heroFearIcon: require('./hero-fear-ic.svg').default,
    heroBillIcon: require('./hero-bill-ic.svg').default,
    dropDownUserOne: require('./dropdown-user-1.svg').default,
    dropDownUserTwo: require('./dropdown-user-2.svg').default,
    aboutUsMockup: require('./about-us-mockup.png'),
    listCheckIcon: require('./list-check-ic.svg').default,
    heartIcon: require('./heart-ic.svg').default,
    mentalDisordersIcon: require('./mental-disorders-ic.svg').default,
    slider1: require('./slider-1.jpg'),
    slider2: require('./slider-2.jpg'),
    slider3: require('./slider-3.jpg'),
    slider4: require('./slider-4.jpg'),
    slider5: require('./slider-5.jpg'),
    slider6: require('./slider-6.jpg'),
    slider7: require('./slider-7.jpg'),
    slider8: require('./slider-8.jpg'),
    sliderPrevIcon: require('./slider-prev-ic.svg').default,
    dashboardOne: require('./dashboard1.png'),
    dashboardTwo: require('./dashboard2.png'),
    onlineConferenceIcon: require('./online-conference-ic.svg').default,
    clockIcon: require('./clock-ic.svg').default,
    medicationIcon: require('./medication-ic.svg').default,
    helthLinkLogoIcon: require('./healthlink-logo-ic.svg').default,
    cmBankLogoIcon: require('./commbank-logo-ic.svg').default,
    erxLogoIcon: require('./erx-logo-ic.svg').default,
    mimsLogoIcon: require('./mims-logo-ic.svg').default,
    agmaLogoIcon: require('./agma-logo-ic.svg').default,
    agadLogoIcon: require('./agad-logo-ic.svg').default,
    testImage1: require('./test-1.png'),
    testImage2: require('./test-2.png'),
    testImage3: require('./test-3.png'),
    leftQuoteIcon: require('./left-quote-ic.svg').default,
    rightQuoteIcon: require('./right-quote-ic.svg').default,
    doctorWithTab: require('./doc-with-tab.png'),
    calendarIcon: require('./calendar-ic.svg').default,
    docLaptopIcon: require('./doc-laptop-ic.svg').default,
    ruralIcon: require('./rural-ic.svg').default,
    paymentIcon: require('./payment-ic.svg').default,
    accessServiceMockup: require('./access-service-mockup.png'),
    linkArrowIcon: require('./link-arrow-ic.svg').default,
    logoWhiteIcon: require('./logo-white-ic.svg').default,
    mailIcon: require('./mail-ic.svg').default,
    phoneIcon: require('./phone-ic.svg').default,
    locationIcon: require('./location-ic.svg').default,
    facebookIcon: require('./facebook-ic.svg').default,
    twitterIcon: require('./twitter-ic.svg').default,
    instagramIcon: require('./instagram-ic.svg').default,
    linkdnIcon: require('./linkedin-ic.svg').default,
    youtubeIcon: require('./youtube-ic.svg').default,
    tableCheckIcon: require('./table-check-ic.svg').default,
    tableCrossIcon: require('./table-cross-ic.svg').default,
    menuIcon: require('./menu-ic.svg').default,
    closecon: require('./close-ic.svg').default,
    expandMoreIcon: require('./expand-more-ic.svg').default,
};
