import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { env } from 'env';
import { toast } from 'react-toastify';
import messages from 'shared/constants/messages';
import { HttpStatusCode } from 'shared/enums/http-status-code';
import { getBaseURL } from 'shared/utills/commonFunctions';

function showLoader() {
    const loaderDiv = document.getElementById('loader');

    if (loaderDiv) {
        loaderDiv.style.display = 'flex';
        loaderDiv.style.visibility = 'visible';
    }
}

function hideLoader() {
    const loaderDiv = document.getElementById('loader');

    if (loaderDiv) {
        loaderDiv.style.display = 'none';
        loaderDiv.style.visibility = 'hidden';
    }
}

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        if (config.url) {
            config.url = getBaseURL(env.REACT_APP_STORE_URL) + config.url;
        }
        showLoader();
        return config;
    },
    (error: AxiosError) => {
        hideLoader();
        if (error.toString()?.includes(messages.NetworkError)) {
            // toast.error(messages.InternetConnectionError);
            return;
        }
        switch (error?.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.ConflictError:
            case HttpStatusCode.InternalServerError:
                toast.error(messages.InternalServerError);
                return;
        }

        return Promise.reject(messages.SomethingWentWrong);
    },
);

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        hideLoader();
        switch (response.data?.statusCode) {
            case HttpStatusCode.Error:
                toast.error(response?.data?.message);
                break;
        }
        return response;
    },
    (error: AxiosError) => {
        hideLoader();
        if (error.toString()?.includes(messages.NetworkError)) {
            // toast.error(messages.InternetConnectionError);
            return;
        }
        switch (error.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.ConflictError:
            case HttpStatusCode.InternalServerError:
                toast.error(messages.InternalServerError);
                return;
        }

        return Promise.reject(messages.SomethingWentWrong);
    },
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
};
