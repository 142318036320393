import React from 'react';
import { useDispatch } from 'react-redux';
import Header from './Header';
import homeService from 'services/home/home-service';
import messages from 'shared/constants/messages';
import { toast } from 'react-toastify';
import { updateSetting } from 'store/actions/setting-actions';

interface IMainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ children }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        (async function fetchSetting() {
            await getSetting();
        })();
    }, []);

    const getSetting = async () => {
        await homeService
            .getSettings()
            .then((response) => {
                dispatch(updateSetting(response?.data?.data));
            })
            .catch(() => toast.error(messages.SomethingWentWrong));
    };
    return (
        <div>
            <Header />
            <div>{children}</div>
        </div>
    );
};

export default MainLayout;
