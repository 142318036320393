import { AuthActionType } from 'store/actionTypes/setting-action-type';
import { ISystemSettingModel } from 'models/setting';

export const updateSetting = (data: ISystemSettingModel) => {
    return {
        type: AuthActionType.UPDATE_SETTING,
        payload: {
            ...data,
        },
    };
};
