/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios';
import { IEnquiryModel } from 'models/enquiry';
import { ApiResponseModel } from 'services/api';
import baseService from 'services/base-service';
import { IBaseSearchRequest, SearchResult } from 'models/base-type';
import { IHealthNewsModel } from 'models/health-news';
import { IFaqModel } from 'models/faq';
import { ISystemSettingModel } from 'models/setting';

const endPointBaseURL = `Home`;

const addEnquiry = async (requestBody: IEnquiryModel): Promise<AxiosResponse<ApiResponseModel<number>>> =>
    baseService.post<ApiResponseModel<number>>(`${endPointBaseURL}/ManageEnquiry?enquiryModel`, requestBody);

const getHealthNews = async (
    requestBody: IBaseSearchRequest,
): Promise<AxiosResponse<ApiResponseModel<SearchResult<IHealthNewsModel>>>> =>
    baseService.post<ApiResponseModel<SearchResult<IHealthNewsModel>>>(`${endPointBaseURL}/GetHealthNews`, requestBody);

const getFaqs = async (
    requestBody: IBaseSearchRequest,
): Promise<AxiosResponse<ApiResponseModel<SearchResult<IFaqModel>>>> =>
    baseService.post<ApiResponseModel<SearchResult<IFaqModel>>>(`${endPointBaseURL}/GetFAQs`, requestBody);

const getSettings = async (): Promise<AxiosResponse<ApiResponseModel<ISystemSettingModel>>> =>
    baseService.get<ApiResponseModel<ISystemSettingModel>>(`${endPointBaseURL}/GetSettings`);

export default {
    addEnquiry,
    getHealthNews,
    getFaqs,
    getSettings,
};
